<template>
  <NuxtLayout>
    <VitePwaManifest />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import "core-js/features/array/at";

const { localeProperties, locale, loadLocaleMessages } = useI18n();
const { cookieOptions } = useCookieOptions();
const titleSuffix = locale.value === "en-US" ? " | Paracord.shop" : "";

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk + titleSuffix}` : `${localeProperties.value.domain}`;
  },
  meta: [
    { charset: "utf8" },
    {
      hid: "viewport",
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    },
  ],
  link: [
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon.ico",
      sizes: "any",
    },
    {
      rel: "icon",
      href: "/favicon.png",
      type: "image/png",
    },
    {
      rel: "apple-touch-icon",
      href: "/apple-touch-icon-180x180.png",
    },
    {
      rel: "apple-touch-startup-image",
      href: "/apple-splash-portrait-dark-1536x2048.png",
      media:
        "screen and (prefers-color-scheme: dark) and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
    },
    {
      rel: "apple-touch-startup-image",
      href: "/apple-splash-landscape-dark-2048x1536.png",
      media:
        "screen and (prefers-color-scheme: dark) and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
    },
    {
      rel: "apple-touch-startup-image",
      href: "/apple-splash-landscape-light-2048x1536.png",
      media:
        "screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
    },
    {
      rel: "apple-touch-startup-image",
      href: "/apple-splash-portrait-light-1536x2048.png",
      media:
        "screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
    },
    {
      rel: "preconnect",
      href: "https://pc.fcdn.eu",
    },
  ],
});
useSeoMeta({
  ogSiteName: "Paracord.shop",
});

// A/B testing complete website (should be done on client side, otherwise it will be cached)
const config = useRuntimeConfig();
onMounted(async () => {
  if (locale.value === "nl-NL") {
    //&& config.public.websiteEnv === "production") {
    const { query } = useRoute();
    const random = Math.floor(Math.random() * 100);
    const threshold = useCookie<number>("PCAB-Threshold", {
      default: () => random,
      ...cookieOptions.value,
      maxAge: 60 * 60 * 24 * 365,
    });
    const testCookie = useCookie<string>("PCAB-Test", {
      default: () => "B",
      ...cookieOptions.value,
      maxAge: 60 * 60 * 24 * 365,
    });
    useLogger().info("AB test", threshold.value, testCookie.value);
    useLogger().info("Override AB Test", !!query.forceWebsiteVersion, query.forceWebsiteVersion);
    threshold.value = query.forceWebsiteVersion ? 100 : (threshold.value ?? random);

    const isBotUserAgent = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

    if (threshold.value < parseInt(config.public.redirectThreshold) || isBotUserAgent) {
      testCookie.value = "A";
      await navigateTo("https://www.paracord.nl", { external: true });
    }
  }
});
</script>
<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  // transform: translateY(20px);
}
</style>
