
// @ts-nocheck


export const localeCodes =  [
  "en-US",
  "en",
  "pl-PL",
  "nl-NL",
  "de-DE",
  "da-DK",
  "en-GB",
  "es-ES",
  "fr-FR",
  "it-IT",
  "sv-SE"
]

export const localeLoaders = {
  "en-US": [{ key: "../lang/en-US", load: () => import("../lang/en-US" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_en_US" */), cache: true }],
  "en": [{ key: "../lang/en-US", load: () => import("../lang/en-US" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_en_US" */), cache: true }],
  "pl-PL": [{ key: "../lang/pl-PL", load: () => import("../lang/pl-PL" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_pl_PL" */), cache: true }],
  "nl-NL": [{ key: "../lang/nl-NL", load: () => import("../lang/nl-NL" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_nl_NL" */), cache: true }],
  "de-DE": [{ key: "../lang/de-DE.json", load: () => import("../lang/de-DE.json" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_de_DE_json" */), cache: true }],
  "da-DK": [{ key: "../lang/da-DK.json", load: () => import("../lang/da-DK.json" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_da_DK_json" */), cache: true }],
  "en-GB": [{ key: "../lang/en-GB.json", load: () => import("../lang/en-GB.json" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_en_GB_json" */), cache: true }],
  "es-ES": [{ key: "../lang/es-ES.json", load: () => import("../lang/es-ES.json" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_es_ES_json" */), cache: true }],
  "fr-FR": [{ key: "../lang/fr-FR.json", load: () => import("../lang/fr-FR.json" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_fr_FR_json" */), cache: true }],
  "it-IT": [{ key: "../lang/it-IT.json", load: () => import("../lang/it-IT.json" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_it_IT_json" */), cache: true }],
  "sv-SE": [{ key: "../lang/sv-SE.json", load: () => import("../lang/sv-SE.json" /* webpackChunkName: "locale__builds_paracord_storefront_nuxt3_lang_sv_SE_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../config/vue.i18n.ts?hash=f51de6d0&config=1" /* webpackChunkName: "__config_vue_i18n_ts_f51de6d0" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/vue.i18n.ts",
  "locales": [
    {
      "name": "en_US",
      "friendlyName": "International (English)",
      "code": "en-US",
      "language": "en-US",
      "defaultCurrency": "USD",
      "isCatchallLocale": true,
      "domain": "https://rc.paracord.shop",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/en-US"
      ]
    },
    {
      "name": "en",
      "friendlyName": "Europe (English)",
      "code": "en",
      "language": "en",
      "defaultCurrency": "EUR",
      "domain": "https://www.paracord.eu",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/en-US"
      ]
    },
    {
      "name": "pl",
      "friendlyName": "Polska",
      "code": "pl-PL",
      "language": "pl-PL",
      "defaultCurrency": "PLN",
      "domain": "https://rc.paracordshop.pl",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/pl-PL"
      ]
    },
    {
      "name": "nl",
      "friendlyName": "Nederland",
      "code": "nl-NL",
      "language": "nl-NL",
      "defaultCurrency": "EUR",
      "domain": "https://rcnew.paracord.nl",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/nl-NL"
      ]
    },
    {
      "name": "de",
      "friendlyName": "Deutschland",
      "code": "de-DE",
      "language": "de-DE",
      "defaultCurrency": "EUR",
      "domain": "https://www.paracord.de",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/de-DE.json"
      ]
    },
    {
      "name": "da",
      "friendlyName": "Danmark",
      "code": "da-DK",
      "language": "da-DK",
      "defaultCurrency": "DKK",
      "domain": "https://www.paracord.eu/da",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/da-DK.json"
      ]
    },
    {
      "name": "en_GB",
      "friendlyName": "United Kingdom",
      "code": "en-GB",
      "language": "en-GB",
      "defaultCurrency": "GBP",
      "domain": "https://www.paracord.co.uk",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/en-GB.json"
      ]
    },
    {
      "name": "es",
      "friendlyName": "España",
      "code": "es-ES",
      "language": "es-ES",
      "defaultCurrency": "EUR",
      "domain": "https://www.paracord.eu/es/",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/es-ES.json"
      ]
    },
    {
      "name": "fr",
      "friendlyName": "France",
      "code": "fr-FR",
      "language": "fr-FR",
      "defaultCurrency": "EUR",
      "domain": "https://www.paracord.fr",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/fr-FR.json"
      ]
    },
    {
      "name": "it",
      "friendlyName": "Italia",
      "code": "it-IT",
      "language": "it-IT",
      "defaultCurrency": "EUR",
      "domain": "https://www.paracord.eu/it",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/it-IT.json"
      ]
    },
    {
      "name": "sv",
      "friendlyName": "Sverige",
      "code": "sv-SE",
      "language": "sv-SE",
      "defaultCurrency": "SEK",
      "domain": "https://www.paracord.eu/sv",
      "domainDefault": true,
      "files": [
        "/builds/paracord/storefront/nuxt3/lang/sv-SE.json"
      ]
    }
  ],
  "defaultLocale": "en-US",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "https://rc.paracord.shop",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "en_US",
    "friendlyName": "International (English)",
    "code": "en-US",
    "language": "en-US",
    "defaultCurrency": "USD",
    "isCatchallLocale": true,
    "domain": "https://rc.paracord.shop",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/en-US"
      }
    ]
  },
  {
    "name": "en",
    "friendlyName": "Europe (English)",
    "code": "en",
    "language": "en",
    "defaultCurrency": "EUR",
    "domain": "https://www.paracord.eu",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/en-US"
      }
    ]
  },
  {
    "name": "pl",
    "friendlyName": "Polska",
    "code": "pl-PL",
    "language": "pl-PL",
    "defaultCurrency": "PLN",
    "domain": "https://rc.paracordshop.pl",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/pl-PL"
      }
    ]
  },
  {
    "name": "nl",
    "friendlyName": "Nederland",
    "code": "nl-NL",
    "language": "nl-NL",
    "defaultCurrency": "EUR",
    "domain": "https://rcnew.paracord.nl",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/nl-NL"
      }
    ]
  },
  {
    "name": "de",
    "friendlyName": "Deutschland",
    "code": "de-DE",
    "language": "de-DE",
    "defaultCurrency": "EUR",
    "domain": "https://www.paracord.de",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/de-DE.json"
      }
    ]
  },
  {
    "name": "da",
    "friendlyName": "Danmark",
    "code": "da-DK",
    "language": "da-DK",
    "defaultCurrency": "DKK",
    "domain": "https://www.paracord.eu/da",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/da-DK.json"
      }
    ]
  },
  {
    "name": "en_GB",
    "friendlyName": "United Kingdom",
    "code": "en-GB",
    "language": "en-GB",
    "defaultCurrency": "GBP",
    "domain": "https://www.paracord.co.uk",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/en-GB.json"
      }
    ]
  },
  {
    "name": "es",
    "friendlyName": "España",
    "code": "es-ES",
    "language": "es-ES",
    "defaultCurrency": "EUR",
    "domain": "https://www.paracord.eu/es/",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/es-ES.json"
      }
    ]
  },
  {
    "name": "fr",
    "friendlyName": "France",
    "code": "fr-FR",
    "language": "fr-FR",
    "defaultCurrency": "EUR",
    "domain": "https://www.paracord.fr",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/fr-FR.json"
      }
    ]
  },
  {
    "name": "it",
    "friendlyName": "Italia",
    "code": "it-IT",
    "language": "it-IT",
    "defaultCurrency": "EUR",
    "domain": "https://www.paracord.eu/it",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/it-IT.json"
      }
    ]
  },
  {
    "name": "sv",
    "friendlyName": "Sverige",
    "code": "sv-SE",
    "language": "sv-SE",
    "defaultCurrency": "SEK",
    "domain": "https://www.paracord.eu/sv",
    "domainDefault": true,
    "files": [
      {
        "path": "/builds/paracord/storefront/nuxt3/lang/sv-SE.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
