import { default as _91_46_46_46slug_936ducuENZl6Meta } from "/builds/paracord/storefront/nuxt3/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93O6rdmg80hjMeta } from "/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue?macro=true";
import { default as cartpyqhtXZG4DMeta } from "/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue?macro=true";
import { default as indexOXQYGxNXShMeta } from "/builds/paracord/storefront/nuxt3/pages/checkout/index.vue?macro=true";
import { default as checkoutAnzWhhu5IhMeta } from "/builds/paracord/storefront/nuxt3/pages/checkout.vue?macro=true";
import { default as _91_46_46_46slug_93Ux9PjSCWPxMeta } from "/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue?macro=true";
import { default as createUCafmWQJogMeta } from "/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue?macro=true";
import { default as index5InBFle4ZZMeta } from "/builds/paracord/storefront/nuxt3/pages/customer/index.vue?macro=true";
import { default as ordersvfn51hzjg7Meta } from "/builds/paracord/storefront/nuxt3/pages/customer/orders.vue?macro=true";
import { default as wishlistsJI6XwleUb2Meta } from "/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue?macro=true";
import { default as customerAYLAKhyZZ4Meta } from "/builds/paracord/storefront/nuxt3/pages/customer.vue?macro=true";
import { default as _91_46_46_46category_93dhQSY901hGMeta } from "/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue?macro=true";
import { default as indexZfp9IgmTn0Meta } from "/builds/paracord/storefront/nuxt3/pages/index.vue?macro=true";
import { default as about3GxAivzOelMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/about.vue?macro=true";
import { default as ambassadorssCiXskYgZVMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/ambassadors.vue?macro=true";
import { default as clientsAndPartnersceyYjKsvCSMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/clientsAndPartners.vue?macro=true";
import { default as contactPK6AhA9S2uMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/contact.vue?macro=true";
import { default as imprintK14iCLixZDMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/imprint.vue?macro=true";
import { default as paracyclepYqzJePA1TMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/paracycle.vue?macro=true";
import { default as paymentMethodsG4UxsLB29tMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/paymentMethods.vue?macro=true";
import { default as returns8An8gtY36eMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/returns.vue?macro=true";
import { default as shipping3tYlyNmIYoMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/shipping.vue?macro=true";
import { default as soldOutpJAHxIjnMCMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/soldOut.vue?macro=true";
import { default as visitStore01JuAn13PbMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/visitStore.vue?macro=true";
import { default as wholesalewC5kkBynv9Meta } from "/builds/paracord/storefront/nuxt3/pages/magento/wholesale.vue?macro=true";
import { default as whyBuyJr36iKhGUEMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/whyBuy.vue?macro=true";
import { default as withOrWithoutVatBe1z72rqaaMeta } from "/builds/paracord/storefront/nuxt3/pages/magento/withOrWithoutVat.vue?macro=true";
import { default as magentoS9kXanOHcGMeta } from "/builds/paracord/storefront/nuxt3/pages/magento.vue?macro=true";
import { default as banners_46groqlmucTP1dZJMeta } from "/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts?macro=true";
import { default as searchclSMQHSNsqMeta } from "/builds/paracord/storefront/nuxt3/pages/search.vue?macro=true";
import { default as index5M69GCLJ4nMeta } from "/builds/paracord/storefront/nuxt3/pages/stores/index.vue?macro=true";
import { default as indexh98nG5m2j0Meta } from "/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue?macro=true";
import { default as successFC56nq0Ag7Meta } from "/builds/paracord/storefront/nuxt3/pages/success.vue?macro=true";
import { default as component_45stubZusNMtiSMSMeta } from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubZusNMtiSMS } from "/builds/paracord/storefront/nuxt3/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en-US",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___pl-PL",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___nl-NL",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___de-DE",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___da-DK",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___en-GB",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___es-ES",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___fr-FR",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___it-IT",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___sv-SE",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936ducuENZl6Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/[...slug].vue")
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___en-US",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___en-US",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___en-US",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___en",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___en",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___en",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___pl-PL",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___pl-PL",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___pl-PL",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___nl-NL",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___nl-NL",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___nl-NL",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___de-DE",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___de-DE",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___de-DE",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___da-DK",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___da-DK",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___da-DK",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___en-GB",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___en-GB",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___en-GB",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___es-ES",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___es-ES",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___es-ES",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___fr-FR",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___fr-FR",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___fr-FR",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___it-IT",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___it-IT",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___it-IT",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: checkoutAnzWhhu5IhMeta?.name,
    path: "/checkout",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout.vue"),
    children: [
  {
    name: "checkout-slug___sv-SE",
    path: ":slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/[...slug].vue")
  },
  {
    name: "checkout-cart___sv-SE",
    path: "cart",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/cart.vue")
  },
  {
    name: "checkout___sv-SE",
    path: "",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/checkout/index.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___en-US",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___en-US",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___en-US",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___en-US",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___en-US",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___en",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___en",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___en",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___en",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___en",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___pl-PL",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___pl-PL",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___pl-PL",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___pl-PL",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___pl-PL",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___nl-NL",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___nl-NL",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___nl-NL",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___nl-NL",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___nl-NL",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___de-DE",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___de-DE",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___de-DE",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___de-DE",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___de-DE",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___da-DK",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___da-DK",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___da-DK",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___da-DK",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___da-DK",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___en-GB",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___en-GB",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___en-GB",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___en-GB",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___en-GB",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___es-ES",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___es-ES",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___es-ES",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___es-ES",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___es-ES",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___fr-FR",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___fr-FR",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___fr-FR",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___fr-FR",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___fr-FR",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___it-IT",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___it-IT",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___it-IT",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___it-IT",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___it-IT",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: customerAYLAKhyZZ4Meta?.name,
    path: "/customer",
    meta: customerAYLAKhyZZ4Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer.vue"),
    children: [
  {
    name: "customer-address-slug___sv-SE",
    path: "address/:slug(.*)*",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/[...slug].vue")
  },
  {
    name: "customer-address-create___sv-SE",
    path: "address/create",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/address/create.vue")
  },
  {
    name: "customer___sv-SE",
    path: "",
    meta: index5InBFle4ZZMeta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/index.vue")
  },
  {
    name: "customer-orders___sv-SE",
    path: "orders",
    meta: ordersvfn51hzjg7Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/orders.vue")
  },
  {
    name: "customer-wishlists___sv-SE",
    path: "wishlists",
    meta: wishlistsJI6XwleUb2Meta || {},
    component: () => import("/builds/paracord/storefront/nuxt3/pages/customer/wishlists.vue")
  }
]
  },
  {
    name: "faq-category___en-US",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___en",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___pl-PL",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___nl-NL",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___de-DE",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___da-DK",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___en-GB",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___es-ES",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___fr-FR",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___it-IT",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "faq-category___sv-SE",
    path: "/faq/:category(.*)*",
    meta: _91_46_46_46category_93dhQSY901hGMeta || {},
    alias: ["/frequently-asked-questions/:category()*"],
    component: () => import("/builds/paracord/storefront/nuxt3/pages/faq/[...category].vue")
  },
  {
    name: "index___en-US",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___pl-PL",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___nl-NL",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___de-DE",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___da-DK",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___en-GB",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___es-ES",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___fr-FR",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___it-IT",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "index___sv-SE",
    path: "/",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/index.vue")
  },
  {
    name: "magento___en",
    path: "/magento",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento.vue"),
    children: [
  {
    name: "magento-about___en",
    path: "/we-are-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/about.vue")
  },
  {
    name: "magento-ambassadors___en",
    path: "/ambassadors-overview-menu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/ambassadors.vue")
  },
  {
    name: "magento-clientsAndPartners___en",
    path: "/our-clients-and-partners",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/clientsAndPartners.vue")
  },
  {
    name: "magento-contact___en",
    path: "/contact-us",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/contact.vue")
  },
  {
    name: "magento-imprint___en",
    path: "/imprint",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/imprint.vue")
  },
  {
    name: "magento-paracycle___en",
    path: "/paracord-paracycle-your-rope",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paracycle.vue")
  },
  {
    name: "magento-paymentMethods___en",
    path: "/payment-method",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paymentMethods.vue")
  },
  {
    name: "magento-returns___en",
    path: "/returns",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/returns.vue")
  },
  {
    name: "magento-shipping___en",
    path: "/shipping-rates",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/shipping.vue")
  },
  {
    name: "magento-soldOut___en",
    path: "/article-sold-out",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/soldOut.vue")
  },
  {
    name: "magento-visitStore___en",
    path: "/visit-our-showroom",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/visitStore.vue")
  },
  {
    name: "magento-wholesale___en",
    path: "/wholesale",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/wholesale.vue")
  },
  {
    name: "magento-whyBuy___en",
    path: "/why-buy-at-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/whyBuy.vue")
  },
  {
    name: "magento-withOrWithoutVat___en",
    path: "/with-or-without-vat",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/withOrWithoutVat.vue")
  }
]
  },
  {
    name: "magento___en-GB",
    path: "/magento",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento.vue"),
    children: [
  {
    name: "magento-about___en-GB",
    path: "/we-are-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/about.vue")
  },
  {
    name: "magento-ambassadors___en-GB",
    path: "/ambassadors-overview-menu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/ambassadors.vue")
  },
  {
    name: "magento-clientsAndPartners___en-GB",
    path: "/our-clients-and-partners",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/clientsAndPartners.vue")
  },
  {
    name: "magento-contact___en-GB",
    path: "/contact-us",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/contact.vue")
  },
  {
    name: "magento-imprint___en-GB",
    path: "/imprint",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/imprint.vue")
  },
  {
    name: "magento-paracycle___en-GB",
    path: "/paracord-paracycle-your-rope",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paracycle.vue")
  },
  {
    name: "magento-paymentMethods___en-GB",
    path: "/payment-method",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paymentMethods.vue")
  },
  {
    name: "magento-returns___en-GB",
    path: "/returns",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/returns.vue")
  },
  {
    name: "magento-shipping___en-GB",
    path: "/shipping-rates",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/shipping.vue")
  },
  {
    name: "magento-soldOut___en-GB",
    path: "/article-sold-out",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/soldOut.vue")
  },
  {
    name: "magento-visitStore___en-GB",
    path: "/visit-our-showroom",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/visitStore.vue")
  },
  {
    name: "magento-wholesale___en-GB",
    path: "/wholesale",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/wholesale.vue")
  },
  {
    name: "magento-whyBuy___en-GB",
    path: "/why-buy-at-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/whyBuy.vue")
  },
  {
    name: "magento-withOrWithoutVat___en-GB",
    path: "/with-or-without-vat",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/withOrWithoutVat.vue")
  }
]
  },
  {
    name: "magento___en-US",
    path: "/magento",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento.vue"),
    children: [
  {
    name: "magento-about___en-US",
    path: "/we-are-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/about.vue")
  },
  {
    name: "magento-ambassadors___en-US",
    path: "/ambassadors-overview-menu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/ambassadors.vue")
  },
  {
    name: "magento-clientsAndPartners___en-US",
    path: "/our-clients-and-partners",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/clientsAndPartners.vue")
  },
  {
    name: "magento-contact___en-US",
    path: "/contact-us",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/contact.vue")
  },
  {
    name: "magento-imprint___en-US",
    path: "/imprint",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/imprint.vue")
  },
  {
    name: "magento-paracycle___en-US",
    path: "/paracord-paracycle-your-rope",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paracycle.vue")
  },
  {
    name: "magento-paymentMethods___en-US",
    path: "/payment-method",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paymentMethods.vue")
  },
  {
    name: "magento-returns___en-US",
    path: "/returns",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/returns.vue")
  },
  {
    name: "magento-shipping___en-US",
    path: "/shipping-rates",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/shipping.vue")
  },
  {
    name: "magento-soldOut___en-US",
    path: "/article-sold-out",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/soldOut.vue")
  },
  {
    name: "magento-visitStore___en-US",
    path: "/visit-our-showroom",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/visitStore.vue")
  },
  {
    name: "magento-wholesale___en-US",
    path: "/wholesale",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/wholesale.vue")
  },
  {
    name: "magento-whyBuy___en-US",
    path: "/why-buy-at-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/whyBuy.vue")
  },
  {
    name: "magento-withOrWithoutVat___en-US",
    path: "/with-or-without-vat",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/withOrWithoutVat.vue")
  }
]
  },
  {
    name: "magento___pl-PL",
    path: "/magento",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento.vue"),
    children: [
  {
    name: "magento-about___pl-PL",
    path: "/jestesmy-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/about.vue")
  },
  {
    name: "magento-ambassadors___pl-PL",
    path: "/ambassadors-overview-menu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/ambassadors.vue")
  },
  {
    name: "magento-clientsAndPartners___pl-PL",
    path: "/nasi-klienci-i-partnerzy",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/clientsAndPartners.vue")
  },
  {
    name: "magento-contact___pl-PL",
    path: "/skontaktuj",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/contact.vue")
  },
  {
    name: "magento-imprint___pl-PL",
    path: "/dane-firmy",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/imprint.vue")
  },
  {
    name: "magento-paracycle___pl-PL",
    path: "/paracord-paracycle-your-rope",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paracycle.vue")
  },
  {
    name: "magento-paymentMethods___pl-PL",
    path: "/metody-platnosci",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paymentMethods.vue")
  },
  {
    name: "magento-returns___pl-PL",
    path: "/polityka-zwrotow",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/returns.vue")
  },
  {
    name: "magento-shipping___pl-PL",
    path: "/wysylka-i-stawki",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/shipping.vue")
  },
  {
    name: "magento-soldOut___pl-PL",
    path: "/artykul-wyprzedany",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/soldOut.vue")
  },
  {
    name: "magento-visitStore___pl-PL",
    path: "/visit-our-showroom",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/visitStore.vue")
  },
  {
    name: "magento-wholesale___pl-PL",
    path: "/hurtownia",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/wholesale.vue")
  },
  {
    name: "magento-whyBuy___pl-PL",
    path: "/dlaczego-wybrac-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/whyBuy.vue")
  },
  {
    name: "magento-withOrWithoutVat___pl-PL",
    path: "/vat-czy-bez-vat",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/withOrWithoutVat.vue")
  }
]
  },
  {
    name: "magento___nl-NL",
    path: "/magento",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento.vue"),
    children: [
  {
    name: "magento-about___nl-NL",
    path: "/wij-zijn-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/about.vue")
  },
  {
    name: "magento-ambassadors___nl-NL",
    path: "/ambassadors-overview-menu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/ambassadors.vue")
  },
  {
    name: "magento-clientsAndPartners___nl-NL",
    path: "clientsAndPartners",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/clientsAndPartners.vue")
  },
  {
    name: "magento-contact___nl-NL",
    path: "/neem-contact-op",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/contact.vue")
  },
  {
    name: "magento-imprint___nl-NL",
    path: "/colofon",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/imprint.vue")
  },
  {
    name: "magento-paracycle___nl-NL",
    path: "/paracord-nl-paracycle-your-rope",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paracycle.vue")
  },
  {
    name: "magento-paymentMethods___nl-NL",
    path: "/betaalmethoden",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/paymentMethods.vue")
  },
  {
    name: "magento-returns___nl-NL",
    path: "/retourservice",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/returns.vue")
  },
  {
    name: "magento-shipping___nl-NL",
    path: "/verzenden",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/shipping.vue")
  },
  {
    name: "magento-soldOut___nl-NL",
    path: "/artikel-uitverkocht",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/soldOut.vue")
  },
  {
    name: "magento-visitStore___nl-NL",
    path: "/bezoek-onze-showroom",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/visitStore.vue")
  },
  {
    name: "magento-wholesale___nl-NL",
    path: "/retail-groothandel",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/wholesale.vue")
  },
  {
    name: "magento-whyBuy___nl-NL",
    path: "/waarom-kopen-bij-paracord-eu",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/whyBuy.vue")
  },
  {
    name: "magento-withOrWithoutVat___nl-NL",
    path: "/met-of-zonder-btw",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/magento/withOrWithoutVat.vue")
  }
]
  },
  {
    name: "queries-banners.groq___en-US",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___en",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___pl-PL",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___nl-NL",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___de-DE",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___da-DK",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___en-GB",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___es-ES",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___fr-FR",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___it-IT",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "queries-banners.groq___sv-SE",
    path: "/queries/banners.groq",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/queries/banners.groq.ts")
  },
  {
    name: "search___en-US",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___pl-PL",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___nl-NL",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___de-DE",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___da-DK",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___en-GB",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___es-ES",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___fr-FR",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___it-IT",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "search___sv-SE",
    path: "/search",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/search.vue")
  },
  {
    name: "stores___en-US",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___en",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___pl-PL",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___nl-NL",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___de-DE",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___da-DK",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___en-GB",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___es-ES",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___fr-FR",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___it-IT",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores___sv-SE",
    path: "/stores",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/index.vue")
  },
  {
    name: "stores-store-switch___en-US",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___en",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___pl-PL",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___nl-NL",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___de-DE",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___da-DK",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___en-GB",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___es-ES",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___fr-FR",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___it-IT",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "stores-store-switch___sv-SE",
    path: "/stores/store/switch",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/stores/store/switch/index.vue")
  },
  {
    name: "success___en-US",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___en",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___pl-PL",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___nl-NL",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___de-DE",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___da-DK",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___en-GB",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___es-ES",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___fr-FR",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___it-IT",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: "success___sv-SE",
    path: "/success",
    component: () => import("/builds/paracord/storefront/nuxt3/pages/success.vue")
  },
  {
    name: component_45stubZusNMtiSMSMeta?.name,
    path: "/paracord_admin/:pathMatch(.*)",
    component: component_45stubZusNMtiSMS
  }
]